var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.modalData.showModal)?_c('BaseModal',[_c('AreaModal',{attrs:{"headline":_vm.modalData.modalHeadline,"modalType":_vm.modalData.modalType,"area":_vm.modalData.existingItem,"areaId":_vm.modalData.existingItemId},on:{"close":function($event){_vm.modalData.showModal = false},"update":_vm.loadData}})],1):_vm._e(),_c('BaseTable',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.areaList,"search":_vm.search},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h3',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"}},[_vm._v("Lokasjoner")]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary","max-width":"50%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-message-question-outline ")])]}}])},[_c('span',[_vm._v(" Dette registeret inneholder lokasjoner som skal knyttes til kurs og studier i Universum. Før du oppretter nye, sjekk om lokasjon finnes fra før ")])]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Legg til lokasjon","icon":"mdi-plus"},on:{"handleClick":_vm.openNewArea}})]},proxy:true},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.navigateToArea(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v("Vis adresse til "+_vm._s(item.place))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.openEditArea(item, item.place)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Rediger "+_vm._s(item.place))])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v("More info about "+_vm._s(item.name))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }